import { ClientApi, OpenAPIConfig } from "@ns/api";

import { environmentVariables } from "../env/enviroment-variables";

const config: Partial<OpenAPIConfig> = {
  BASE: environmentVariables.baseApiUrl,
  VERSION: "1",
  CREDENTIALS: "include",
  WITH_CREDENTIALS: true,
};

export const api = new ClientApi(config);
