/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckFreeRoundResponse } from "../models/CheckFreeRoundResponse";
import type { ConfirmedBidsRequest } from "../models/ConfirmedBidsRequest";
import type { GetOrdersByPromoCodeResponse } from "../models/GetOrdersByPromoCodeResponse";
import type { GetRoundOrdersResponse } from "../models/GetRoundOrdersResponse";
import type { PageableGetAdminOrders } from "../models/PageableGetAdminOrders";
import type { PageableUserOrdersResponse } from "../models/PageableUserOrdersResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class OrdersApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get round orders
   * @param id
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns GetRoundOrdersResponse Get round orders
   * @throws ApiError
   */
  public getRoundOrders(
    id: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<GetRoundOrdersResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/orders/{id}",
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get All User Orders
   * @param filter
   * @param currencyCode
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableUserOrdersResponse Get All User Orders
   * @throws ApiError
   */
  public getAllOrders(
    filter?: "ACTIVE" | "ALL" | "WINNING" | "ALL_CONFIRMED",
    currencyCode?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableUserOrdersResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/orders/",
      query: {
        filter: filter,
        currencyCode: currencyCode,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check free round
   * @param id
   * @returns CheckFreeRoundResponse Check free round response
   * @throws ApiError
   */
  public checkFreeRound(id: string): CancelablePromise<CheckFreeRoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/orders/check-free-round/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get orders by promo code
   * @param promoCode
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns GetOrdersByPromoCodeResponse Get orders by promo code
   * @throws ApiError
   */
  public getOrdersByPromoCode(
    promoCode: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<GetOrdersByPromoCodeResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/orders/coupon/{promoCode}",
      path: {
        promoCode: promoCode,
      },
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Download confirmed bids csv
   * @param id
   * @param requestBody Confirmed bids request
   * @returns any Successfully downloaded CSV
   * @throws ApiError
   */
  public downloadConfirmedBids(
    id: string,
    requestBody?: ConfirmedBidsRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/orders/{id}/confirmed-bids/download",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get all orders
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableGetAdminOrders Get all orders
   * @throws ApiError
   */
  public getAllAdminOrders(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableGetAdminOrders> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/orders",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
