/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class TestApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Indicates if test is active endpoint
   * @returns boolean Indicates if test is active endpoint
   * @throws ApiError
   */
  public testActive(): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: "GET",
      url: "/app/test",
      errors: {
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }
}
