import { Card } from "@ns/styles";
import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}
const LoginArea: FC<Props> = ({ children }) => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <div className="shadow-lg rounded-none w-[40%] h-[72%] min-w-96 flex flex-col justify-center items-center pb-32 ">
        <Card className="border-none shadow-none rounded-none min-w-72 ">
          {children}
        </Card>
      </div>
    </div>
  );
};

export default LoginArea;
