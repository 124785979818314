import { Button, Card } from "@ns/styles";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { api } from "../api/client-api";
import useUserStore from "../store/user-store.ts";

const Header = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const { user, setUser } = useUserStore();

  const { mutate: logout } = useMutation({
    mutationFn: () => api.auth.logout(),
    onSuccess: async () => {
      setUser(null);
      navigate("/");
    },
    onError: (error) => {
      console.error("Login failed", error);
      alert("Login failed. Please check your credentials.");
    },
  });

  if (!user || location.pathname === "/") {
    return null;
  }

  return (
    <>
      <Card className="flex gap-5 p-3 items-center mb-8 justify-between">
        <div className="flex items-start gap-5">
          <Link
            to="/dashboard"
            className={`${
              currentPath === "/dashboard" ? "font-bold text-blue-500" : ""
            }`}
          >
            {t("header.dashboard")}
          </Link>
          {user?.email === "china@influencer.com" && (
            <Link
              to="/chat"
              className={`${
                currentPath === "/chat" ? "font-bold text-blue-500" : ""
              }`}
            >
              {t("header.chat")}
            </Link>
          )}
          <Link
            to="/comments"
            className={`${
              currentPath === "/comments" ? "font-bold text-blue-500" : ""
            }`}
          >
            {t("header.comments")}
          </Link>
        </div>
        <Button onClick={() => logout()}>
          {t("form.authentication.logout")}
        </Button>
      </Card>
    </>
  );
};

export default Header;
