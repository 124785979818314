/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckReferralCodeResponse } from "../models/CheckReferralCodeResponse";
import type { GetReferralCodeResponse } from "../models/GetReferralCodeResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ReferralCodeApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get referral code by currencyId
   * @param id
   * @returns GetReferralCodeResponse Successfully get referral code
   * @throws ApiError
   */
  public getReferralCodeByCurrencyId(
    id: string,
  ): CancelablePromise<GetReferralCodeResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/referral-code/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check referral code
   * @param code
   * @param currencyId
   * @returns CheckReferralCodeResponse Successfully check referral code
   * @throws ApiError
   */
  public checkReferralCode(
    code: string,
    currencyId?: string,
  ): CancelablePromise<CheckReferralCodeResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/referral-code",
      query: {
        code: code,
        currencyId: currencyId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
