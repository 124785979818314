/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ValidateForgottenCodeRequest } from "../models/ValidateForgottenCodeRequest";
import type { ValidateForgottenCodeResponse } from "../models/ValidateForgottenCodeResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ForgottenCodeApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send forgotten code email
   * @returns any Sent forgotten code email
   * @throws ApiError
   */
  public sendForgottenCodeEmail(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/forgotten-code/send",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Resend forgotten code email
   * @returns any Resent forgotten code email
   * @throws ApiError
   */
  public resendForgottenCodeEmail(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/forgotten-code/resend",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Validate forgotten code
   * @param requestBody Validate forgotten code
   * @returns ValidateForgottenCodeResponse Validate forgotten code
   * @throws ApiError
   */
  public validateForgottenCode(
    requestBody?: ValidateForgottenCodeRequest,
  ): CancelablePromise<ValidateForgottenCodeResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/forgotten-code/validate",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
