import { Button, Input } from "@ns/styles";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { ChatFeed, Message } from "react-chat-ui";

import { getAllComments, sendComment } from "../../graphql/api.ts";
import { TextWithLinks } from "../components/linkify-text.tsx";
import useUserStore from "../store/user-store.ts";

const Comments = () => {
  const { data, refetch, isLoading, isError } = useQuery({
    queryKey: [`getAllComments`],
    queryFn: () => getAllComments({ offset: 0, limit: 1000 }),
  });

  const { user } = useUserStore();

  const [comments, setComments] = useState<unknown[]>([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    if (data?.getAllComments?.items) {
      setComments(
        data.getAllComments?.items?.map(
          (comment) =>
            new Message({
              id: comment!.user!.id!,
              senderName: comment!.user!.username!,
              // @ts-ignore
              message: (
                <TextWithLinks
                  text={`${comment?.comment} ${comment?.imageUrl}`}
                />
              ),
            }),
        ),
      );
    }
  }, [data]);

  const handleSend = async () => {
    if (input.trim() === "") return;

    await sendComment({
      comment: {
        comment: input,
        user: {
          id: user!.id,
          username: `${user!.firstName} ${user!.lastName}`,
          isInfluencer: true,
        },
      },
    });

    await refetch(); // Refresh messages after sending a new one
    setInput("");
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error :(</p>;

  return (
    <div>
      <ChatFeed
        messages={comments}
        hasInputField={false}
        showSenderName={true}
        bubbleStyles={{
          text: {
            fontSize: 16,
          },
        }}
      />
      <div className="flex w-full flex-row gap-4">
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSend();
            }
          }}
        />
        <Button onClick={handleSend}>Send</Button>
      </div>
    </div>
  );
};

export default Comments;
