/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminRoundAnalyticsResponse } from "../models/AdminRoundAnalyticsResponse";
import type { CheckCompleteRoundResponse } from "../models/CheckCompleteRoundResponse";
import type { CreateRoundRequest } from "../models/CreateRoundRequest";
import type { PageableRecentRoundResponse } from "../models/PageableRecentRoundResponse";
import type { RoundResponse } from "../models/RoundResponse";
import type { RoundsResponse } from "../models/RoundsResponse";
import type { UpdateRoundProgressRequest } from "../models/UpdateRoundProgressRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class RoundApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Round
   * @param requestBody Round body schema
   * @returns any Successfully created bond
   * @throws ApiError
   */
  public createRound(requestBody?: CreateRoundRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/rounds",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get admin rounds
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableRecentRoundResponse Round response
   * @throws ApiError
   */
  public getAdminRounds(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableRecentRoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/rounds",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get rounds
   * @param filter
   * @param currencyCode
   * @param currencyId
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns RoundsResponse Round response
   * @throws ApiError
   */
  public getRounds(
    filter?: "LATEST" | "ALL" | "PREVIOUS",
    currencyCode?: string,
    currencyId?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<RoundsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/rounds",
      query: {
        filter: filter,
        currencyCode: currencyCode,
        currencyId: currencyId,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get active round by currency id
   * @param id
   * @returns RoundResponse Round response
   * @throws ApiError
   */
  public getAdminActiveRoundByCurrencyId(
    id: string,
  ): CancelablePromise<RoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/rounds/active-round/currency/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update round
   * @param id
   * @param requestBody Update round body schema
   * @returns RoundResponse Success update round
   * @throws ApiError
   */
  public updateAdminRound(
    id: string,
    requestBody?: UpdateRoundProgressRequest,
  ): CancelablePromise<RoundResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/admin/rounds/{id}",
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get round by id
   * @param id
   * @returns RoundResponse Round response
   * @throws ApiError
   */
  public getAdminRoundById(id: string): CancelablePromise<RoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/rounds/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check if round can be completed
   * @param id
   * @returns CheckCompleteRoundResponse Check if round can be completed response
   * @throws ApiError
   */
  public checkCompleteRound(
    id: string,
  ): CancelablePromise<CheckCompleteRoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/rounds/{id}/complete",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Complete round
   * @param id
   * @returns RoundResponse Success completed round
   * @throws ApiError
   */
  public completeRound(id: string): CancelablePromise<RoundResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/admin/rounds/{id}/complete",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Round analytics
   * @param id
   * @returns AdminRoundAnalyticsResponse Round analytics
   * @throws ApiError
   */
  public getRoundAnalytics(
    id: string,
  ): CancelablePromise<AdminRoundAnalyticsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/rounds/{id}/analytics",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get round by id
   * @param id
   * @returns RoundResponse Round response
   * @throws ApiError
   */
  public getRoundById(id: string): CancelablePromise<RoundResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/rounds/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }
}
