/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DashboardAnalyticsResponse } from "../models/DashboardAnalyticsResponse";
import type { GetRoundsAnalyticsResponse } from "../models/GetRoundsAnalyticsResponse";
import type { ReferralAnalyticsResponse } from "../models/ReferralAnalyticsResponse";
import type { UserAnalyticsResponse } from "../models/UserAnalyticsResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class AnalyticsApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Dashboard analytics
   * @returns DashboardAnalyticsResponse Dashboard analytics
   * @throws ApiError
   */
  public getDashboardAnalytics(): CancelablePromise<DashboardAnalyticsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/analytics",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * User analytics
   * @param currencyCode
   * @returns UserAnalyticsResponse User analytics
   * @throws ApiError
   */
  public getUserAnalytics(
    currencyCode?: string,
  ): CancelablePromise<UserAnalyticsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/analytics",
      query: {
        currencyCode: currencyCode,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * User referral analytics
   * @param currencyCode
   * @returns ReferralAnalyticsResponse User referral analytics
   * @throws ApiError
   */
  public getReferralAnalytics(
    currencyCode?: string,
  ): CancelablePromise<ReferralAnalyticsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/analytics/referrals",
      query: {
        currencyCode: currencyCode,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get rounds analytics
   * @param currencyId
   * @returns GetRoundsAnalyticsResponse Rounds analytics response
   * @throws ApiError
   */
  public getRoundsAnalytics(
    currencyId?: string,
  ): CancelablePromise<GetRoundsAnalyticsResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/analytics/rounds",
      query: {
        currencyId: currencyId,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
