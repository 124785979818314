import { Toaster } from "@ns/styles";
import { Outlet } from "react-router-dom";

import Header from "../header.tsx";

const MainLayout = () => {
  return (
    <div
      className={`overflow-y-auto hide-scrollbar overflow-x-hidden bg-background p-10`}
    >
      <Header />
      <Outlet />
      <Toaster />
    </div>
  );
};

export default MainLayout;
