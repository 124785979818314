import React from "react";
import SyncLoader from "react-spinners/SyncLoader";

interface LoaderProps {
  color?: string;
  loading?: boolean;
  margin?: number;
  size?: number;
  speedMultiplier?: number;
}

const Loader: React.FC<LoaderProps> = ({
  color = "#abacb2",
  loading = true,
  margin = 2,
  size = 15,
  speedMultiplier = 0,
}) => {
  return (
    <div className="flex h-full w-full justify-center items-center">
      <SyncLoader
        color={color}
        loading={loading}
        margin={margin}
        size={size}
        speedMultiplier={speedMultiplier}
      />
    </div>
  );
};

export default Loader;
