/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminsResponse } from "../models/AdminsResponse";
import type { CreateAdminRequest } from "../models/CreateAdminRequest";
import type { InfluencersResponse } from "../models/InfluencersResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class AdminApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * CreateAdmin
   * @param requestBody Create admin body schema
   * @returns any Admin Created
   * @throws ApiError
   */
  public createAdmin(requestBody?: CreateAdminRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/admins",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get admins
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns any Get admins response
   * @throws ApiError
   */
  public getAdmins(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<{
    items: Array<AdminsResponse>;
    totalItems: number;
    totalPages: number;
    page: number;
  }> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/admins",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Suspend the admin, in any state, except when already suspended or deleted.
   * @param id
   * @returns any Successfully suspended admin
   * @throws ApiError
   */
  public suspendAdmin(id: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/admin/admins/{id}/suspend",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get influencers
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns InfluencersResponse Get influencers response
   * @throws ApiError
   */
  public getInfluencers(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<InfluencersResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/users/all/influencers",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
