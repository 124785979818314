/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckUnclaimedDealResponse } from "../models/CheckUnclaimedDealResponse";
import type { PageableRoundWinnerResponse } from "../models/PageableRoundWinnerResponse";
import type { UpdateUnclaimedDealRequest } from "../models/UpdateUnclaimedDealRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class WinnersApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get pageable round winners
   * @param currencyCode
   * @param currencyId
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableRoundWinnerResponse Pageable round winners response
   * @throws ApiError
   */
  public getWinners(
    currencyCode?: string,
    currencyId?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableRoundWinnerResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/winners",
      query: {
        currencyCode: currencyCode,
        currencyId: currencyId,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get pageable round winners
   * @param currencyCode
   * @param currencyId
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableRoundWinnerResponse Pageable round winners response
   * @throws ApiError
   */
  public getAdminWinners(
    currencyCode?: string,
    currencyId?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableRoundWinnerResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/winners",
      query: {
        currencyCode: currencyCode,
        currencyId: currencyId,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check unclaimed deals
   * @returns CheckUnclaimedDealResponse Unclaimed deals
   * @throws ApiError
   */
  public checkUnclaimedDeals(): CancelablePromise<CheckUnclaimedDealResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/winners/unclaimed",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update unclaimed deals
   * @param requestBody Update unclaimed deals request
   * @returns any Updated unclaimed deals
   * @throws ApiError
   */
  public updateUnclaimedDeals(
    requestBody?: UpdateUnclaimedDealRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/winners/unclaimed",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
