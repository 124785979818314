import { Input } from "@ns/styles";
import { ChangeEvent, FC, MouseEventHandler } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";

type ControlledInputProps = {
  type?: "text" | "password" | "number";
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  id?: string;
  onBlur?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors?: FieldErrors<FieldValues>;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
};

const ControlledInput: FC<ControlledInputProps> = ({
  label,
  placeholder,
  name = "",
  className,
  disabled = false,
  autoFocus,
  id,
  type = "text",
  onBlur,
  onClick,
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className="flex flex-col gap-1 w-full relative">
          <Input
            id={id}
            value={field.value ?? ""}
            onChange={field.onChange}
            onBlur={onBlur}
            label={label}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            autoFocus={autoFocus}
            onClick={onClick}
            className={className}
          />
          {error?.message && (
            <span className="absolute mt-10 text-red-600">{error.message}</span>
          )}
        </div>
      )}
    />
  );
};

export default ControlledInput;
