import { zodResolver } from "@hookform/resolvers/zod";
import React, { FC } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues?: any;
}

const ControlledForm: FC<Props> = ({ children, schema, defaultValues }) => {
  const { t } = useTranslation();

  const validationSchema = typeof schema === "function" ? schema(t) : schema;

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(validationSchema),
    mode: "onChange",
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export default ControlledForm;
