import Linkify from "linkify-react";

type TextWithLinksOptions = {
  text: string;
  className?: string;
};

export const TextWithLinks = ({ text }: TextWithLinksOptions) => (
  <div className="break-words">
    <Linkify
      options={{
        target: "_blank",
        rel: "noopener noreferrer",
        attributes: {
          style: { wordBreak: "break-word" },
          className: "custom-link",
        },
      }}
    >
      {text}
    </Linkify>
  </div>
);
