import { HttpClient } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { I18nextProvider } from "react-i18next";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import App from "./app.tsx";
import { environmentVariables } from "./env/enviroment-variables.ts";
import localization from "./localization";

document.title = import.meta.env.VITE_APP_INFLUENCER_TITLE || "Default Title";

Sentry.init({
  environment: environmentVariables.nodeEnv,
  dsn: environmentVariables.sentryDsn,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
    new HttpClient({
      failedRequestStatusCodes: [[400, 599]],
      failedRequestTargets: [environmentVariables.sentryAppUrl],
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    environmentVariables.sentryAppUrl,
    environmentVariables.baseApiUrl,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <I18nextProvider i18n={localization}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
);
