import { Card, CardHeader, CardContent } from "@ns/styles";

export interface CardDataType {
  title: string;
  value: string | React.ReactNode;
  svg?: React.FC;
}

const CardData = ({ title, value, svg: Svg }: CardDataType) => {
  return (
    <Card className="flex-1">
      <CardHeader className="flex flex-row items-center">
        <span className="flex-1 text-lg font-medium">{title}</span>
        {Svg && <Svg />}
      </CardHeader>
      <CardContent>
        <span className="text-2xl font-bold">{value}</span>
      </CardContent>
    </Card>
  );
};

export default CardData;
