/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountriesResponse } from "../models/CountriesResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class CountryApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get countries
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns CountriesResponse Get countries response
   * @throws ApiError
   */
  public getCountries(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<CountriesResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/country",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
    });
  }
}
