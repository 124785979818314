/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeaturedByImagesResponse } from "../models/FeaturedByImagesResponse";
import type { OnboardingVideoResponse } from "../models/OnboardingVideoResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ContentfulApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get onboarding video
   * @returns OnboardingVideoResponse Onboarding video
   * @throws ApiError
   */
  public getOnboardingVideo(): CancelablePromise<OnboardingVideoResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/contentful/onboarding",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get featured by images
   * @returns FeaturedByImagesResponse Featured images
   * @throws ApiError
   */
  public getFeaturedByImages(): CancelablePromise<FeaturedByImagesResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/contentful/featured-by",
      errors: {
        400: `Bad request`,
        500: `Something went wrong`,
      },
    });
  }
}
