/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UploadFileResponse } from "../models/UploadFileResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class UploadApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Upload file
   * @param type
   * @param formData
   * @returns UploadFileResponse Uploaded file
   * @throws ApiError
   */
  public uploadFile(
    type: "users" | "chat",
    formData?: {
      file?: Blob;
    },
  ): CancelablePromise<UploadFileResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/upload",
      query: {
        type: type,
      },
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
