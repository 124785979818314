/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckNotifyMeResponse } from "../models/CheckNotifyMeResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class NotifyMeApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Subscribe to notify me
   * @param id
   * @returns any Successfully subscribed to notify me
   * @throws ApiError
   */
  public subscribeToNotifyMe(id: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/notify-me/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check if user is subscribed to notify me
   * @param id
   * @returns CheckNotifyMeResponse Successfully subscribed to notify me
   * @throws ApiError
   */
  public checkIfUserIsSubscribedToNotifyMe(
    id: string,
  ): CancelablePromise<CheckNotifyMeResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/notify-me/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
