/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RegisterDeviceRequest } from "../models/RegisterDeviceRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class PushRegistrationApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Register device for push notifications
   * @param requestBody Register device for push notifications
   * @returns any Successfully registered device
   * @throws ApiError
   */
  public registerDevice(
    requestBody?: RegisterDeviceRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/push-registrations",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
