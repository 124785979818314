/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendCustomNotificationRequest } from "../models/SendCustomNotificationRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class NotificationsApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send custom notification
   * @param requestBody
   * @returns any Successfully sent notifications
   * @throws ApiError
   */
  public sendCustomNotification(
    requestBody?: SendCustomNotificationRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/notifications",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
