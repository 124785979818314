/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckPinCodeRequest } from "../models/CheckPinCodeRequest";
import type { CheckPinCodeResponse } from "../models/CheckPinCodeResponse";
import type { CreatePinCodeRequest } from "../models/CreatePinCodeRequest";
import type { GetPinCodeResponse } from "../models/GetPinCodeResponse";
import type { UpdatePinCodeRequest } from "../models/UpdatePinCodeRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class PinCodeApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get pin code
   * @returns GetPinCodeResponse Get pin code
   * @throws ApiError
   */
  public getPinCode(): CancelablePromise<GetPinCodeResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/pin-code",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Create pin code
   * @param requestBody Create pin code
   * @returns any Created pin code
   * @throws ApiError
   */
  public createPinCode(
    requestBody?: CreatePinCodeRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/pin-code",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update pin code
   * @param requestBody Update pin code
   * @returns any Updated pin code
   * @throws ApiError
   */
  public updatePinCode(
    requestBody?: UpdatePinCodeRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/pin-code",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Toggle pin code activity
   * @returns any Toggle pin code activity
   * @throws ApiError
   */
  public togglePinCodeActivity(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/pin-code/toggle-activity",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Validate pin code
   * @param requestBody Validate pin code
   * @returns CheckPinCodeResponse Validated pin code
   * @throws ApiError
   */
  public validatePinCode(
    requestBody?: CheckPinCodeRequest,
  ): CancelablePromise<CheckPinCodeResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/pin-code/validate",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
