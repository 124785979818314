/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendMessageToSupportRequest } from "../models/SendMessageToSupportRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class SupportApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Send email to support
   * @param requestBody Send email to support request
   * @returns any Successfully sent email to support
   * @throws ApiError
   */
  public sendEmail(
    requestBody?: SendMessageToSupportRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/support",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
