import { TFunction } from "i18next";
import { z } from "zod";

import { staticTranslationFunction } from "../../utils/form/static-translation-function.ts";
import { FormNames } from "../name-generator";

export const loginValidationSchema = (t: TFunction) =>
  z.object({
    email: z
      .string({ message: t("form.required", { name: t("form.email") }) })
      .email({
        message: t("form.authentication.invalid", { name: t("form.email") }),
      }),
    password: z
      .string({ message: t("form.required", { name: t("form.password") }) })
      .min(8, t("form.shouldContain", { name: t("form.password"), number: 8 })),
  });

export const schema = loginValidationSchema(staticTranslationFunction);

export type LoginFormData = z.infer<typeof schema>;

export const loginFormFieldNames: FormNames<LoginFormData> = {
  email: "email",
  password: "password",
};
