// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { GraphQLClient } from "graphql-request";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import gql from "graphql-tag";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import * as Dom from "graphql-request/dist/types.dom";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */

export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type IChatDataInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<IChatDataUserInput>;
};

export type IChatDataResponse = {
  message?: Maybe<Scalars["String"]["output"]>;
  time?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<IChatDataUserResponse>;
};

export type IChatDataUserInput = {
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type IChatDataUserResponse = {
  displayName?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
};

export type IChatMessageSentResponse = {
  data?: Maybe<IChatDataResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  event?: Maybe<Scalars["String"]["output"]>;
};

export type IChatResponse = {
  items?: Maybe<Array<Maybe<IChatDataResponse>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type ICommentDataInput = {
  comment?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  user?: InputMaybe<ICommentDataUserInput>;
};

export type ICommentDataResponse = {
  comment?: Maybe<Scalars["String"]["output"]>;
  time?: Maybe<Scalars["String"]["output"]>;
  user?: Maybe<ICommentDataUserResponse>;
};

export type ICommentDataUserInput = {
  username?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isInfluencer?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ICommentDataUserResponse = {
  username?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  isInfluencer?: InputMaybe<Scalars["Boolean"]["output"]>;
};

export type ICommentSentResponse = {
  data?: Maybe<ICommentDataResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  event?: Maybe<Scalars["String"]["output"]>;
};

export type ICommentResponse = {
  items?: Maybe<Array<Maybe<ICommentDataResponse>>>;
  count?: Maybe<Scalars["Int"]>;
};

export type INotificationResponse = {
  createdAt?: Maybe<Scalars["String"]>;
  notification?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  userId?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type IPageableNotificationResponse = {
  count?: Maybe<Scalars["Int"]>;
  items?: Maybe<Array<Maybe<INotificationResponse>>>;
  page?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export type IUploadImageInput = {
  path?: InputMaybe<Scalars["String"]["input"]>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  fileType?: InputMaybe<Scalars["String"]["input"]>;
  folder?: InputMaybe<Scalars["String"]["input"]>;
};

export type IUploadUserImageResponse = {
  error?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
};

export type IQueryGetNotificationsByUserIdArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  userId?: InputMaybe<Scalars["String"]>;
};

export type IMutation = {
  sendMessage?: Maybe<IChatMessageSentResponse>;
  uploadUserImage?: Maybe<IUploadUserImageResponse>;
  sendComment?: Maybe<ICommentSentResponse>;
};

export type IMutationSendMessageArgs = {
  chatData?: InputMaybe<IChatDataInput>;
};

export type IQuery = {
  getAllMessages?: Maybe<IChatResponse>;
  getNotificationsByUserId?: Maybe<IPageableNotificationResponse>;
  getAllComments?: Maybe<ICommentResponse>;
};

export type ISubscription = {
  onSendMessage?: Maybe<IChatMessageSentResponse>;
  onSendComment?: Maybe<ICommentSentResponse>;
};

export type ISendMessageMutationVariables = Exact<{
  chatData?: InputMaybe<IChatDataInput>;
}>;

export type ISendCommentMutationVariables = Exact<{
  comment?: InputMaybe<ICommentDataInput>;
}>;

export type IGetNotificationsByUserIdVariables = Exact<{
  userId?: InputMaybe<Scalars["String"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ISendMessageMutation = {
  sendMessage?: {
    error?: string | null;
    data?: {
      message?: string | null;
      user?: { id?: string | null; displayName?: string | null } | null;
    } | null;
  } | null;
};

export type ISendCommentMutation = {
  sendComment?: {
    error?: string | null;
    data?: {
      comment?: string | null;
      user?: {
        id?: string | null;
        username?: string | null;
        isInfluencer?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type IUploadImageMutation = {
  uploadUserImage?: {
    error?: string | null;
    key?: string | null;
  } | null;
};

export type IOnSendMessageSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type IOnSendCommentSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type IOnSendMessageSubscription = {
  onSendMessage?: {
    error?: string | null;
    event?: string | null;
    data?: {
      message?: string | null;
      time?: string | null;
      imageUrl?: string | null;
      user?: { id?: string | null; displayName?: string | null } | null;
    } | null;
  } | null;
};

export type IOnSendCommentSubscription = {
  onSendComment?: {
    error?: string | null;
    event?: string | null;
    data?: {
      comment?: string | null;
      time?: string | null;
      imageUrl?: string | null;
      user?: {
        id?: string | null;
        username?: string | null;
        isInfluencer?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type IGetAllMessagesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type IGetAllCommentsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type IGetAllMessagesQuery = {
  getAllMessages?: {
    error?: string | null;
    messages?: Array<{
      message?: string | null;
      imageUrl?: string | null;
      time?: string | null;
      user?: { id?: string | null; displayName?: string | null } | null;
    } | null> | null;
  } | null;
};

export type IGetAllCommentsQuery = {
  getAllComments?: {
    error?: string | null;
    items?: Array<{
      comment?: string | null;
      imageUrl?: string | null;
      time?: string | null;
      user?: {
        id?: string | null;
        username?: string | null;
        isInfluencer?: boolean | null;
      } | null;
    } | null> | null;
  } | null;
};

export type IGetNotificationsByUserIdQuery = {
  getNotificationsByUserId?: {
    error?: string | null;
    items?: Array<{
      userId?: string | null;
      notification?: string | null;
      title?: string | null;
      createdAt?: string | null;
      type?: string | null;
    } | null> | null;
    count?: number | null;
    page?: number | null;
    totalPages?: number | null;
  } | null;
};

export const SendMessageDocument = gql`
  mutation sendMessage($chatData: ChatDataInput) {
    sendMessage(chatData: $chatData) {
      error
      data {
        user {
          id
          displayName
        }
        message
        imageUrl
        time
      }
    }
  }
`;

export const SendCommentDocument = gql`
  mutation sendComment($comment: CommentDataInput) {
    sendComment(comment: $comment) {
      error
      data {
        user {
          id
          username
          isInfluencer
        }
        comment
        imageUrl
        time
      }
    }
  }
`;

export const UploadImageDocument = gql`
  mutation uploadImage(
    $path: String
    $fileName: String
    $fileType: String
    $folder: String
  ) {
    uploadImage(
      path: $path
      fileName: $fileName
      fileType: $fileType
      folder: $folder
    ) {
      error
      key
    }
  }
`;
export const OnSendMessageDocument = gql`
  subscription onSendMessage {
    onSendMessage {
      error
      event
      data {
        user {
          id
          displayName
        }
        message
        imageUrl
        time
      }
    }
  }
`;
export const OnSendCommentDocument = gql`
  subscription onSendComment {
    onSendComment {
      error
      event
      data {
        user {
          id
          username
          isInfluencer
        }
        comment
        imageUrl
        time
      }
    }
  }
`;
export const GetAllChatMessagesDocument = gql`
  query getAllMessages($offset: Int, $limit: Int) {
    getAllMessages(offset: $offset, limit: $limit) {
      items {
        user {
          id
          displayName
        }
        message
        imageUrl
        time
      }
      count
      page
      totalPages
    }
  }
`;
export const GetAllChatCommentsDocument = gql`
  query getAllComments($offset: Int, $limit: Int) {
    getAllComments(offset: $offset, limit: $limit) {
      items {
        user {
          id
          username
          isInfluencer
        }
        comment
        imageUrl
        time
      }
      count
      page
      totalPages
    }
  }
`;
export const GetNotificationsByUserIdDocument = gql`
  query GetNotificationsByUserId($userId: String, $offset: Int, $limit: Int) {
    getNotificationsByUserId(userId: $userId, offset: $offset, limit: $limit) {
      items {
        userId
        createdAt
        notification
        title
        type
      }
      count
      page
      totalPages
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    sendMessage(
      variables?: ISendMessageMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ISendMessageMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ISendMessageMutation>(SendMessageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "sendMessage",
        "mutation",
      );
    },
    onSendMessage(
      variables?: IOnSendMessageSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IOnSendMessageSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IOnSendMessageSubscription>(
            OnSendMessageDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            },
          ),
        "onSendMessage",
        "subscription",
      );
    },
    sendComment(
      variables?: ISendCommentMutationVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<ISendCommentMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ISendCommentMutation>(SendCommentDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "sendComment",
        "mutation",
      );
    },
    onSendComment(
      variables?: IOnSendCommentSubscriptionVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IOnSendCommentSubscription> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IOnSendCommentSubscription>(
            OnSendCommentDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            },
          ),
        "onSendComment",
        "subscription",
      );
    },
    getAllMessages(
      variables?: IGetAllMessagesQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IGetAllMessagesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IGetAllMessagesQuery>(
            GetAllChatMessagesDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            },
          ),
        "getAllMessages",
        "query",
      );
    },
    getAllComments(
      variables?: IGetAllCommentsQueryVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IGetAllCommentsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IGetAllCommentsQuery>(
            GetAllChatCommentsDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            },
          ),
        "getAllComments",
        "query",
      );
    },
    getNotificationsByUserId(
      variables?: IGetNotificationsByUserIdVariables,
      requestHeaders?: Dom.RequestInit["headers"],
    ): Promise<IGetNotificationsByUserIdQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IGetNotificationsByUserIdQuery>(
            GetNotificationsByUserIdDocument,
            variables,
            {
              ...requestHeaders,
              ...wrappedRequestHeaders,
            },
          ),
        "getNotificationsByUserId",
        "query",
      );
    },
  };
}
