/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetActiveCoinPaymentsCurrency } from "../models/GetActiveCoinPaymentsCurrency";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class CoinPaymentsConfigurationApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get CoinPayments Active Configuration
   * @param id
   * @returns GetActiveCoinPaymentsCurrency Get CoinPayments Active Configuration
   * @throws ApiError
   */
  public getCoinPaymentsActiveConfigurationByFiatCurrencyId(
    id: string,
  ): CancelablePromise<GetActiveCoinPaymentsCurrency> {
    return this.httpRequest.request({
      method: "GET",
      url: "/coin-payments-config/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
