import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

type User = {
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  imageUrl?: string | undefined;
};

export type Auth = {
  user?: User | null;
  setUser: (user: User | null) => void;
};

export const useUserStore = create<Auth>()(
  devtools(
    immer(
      (set): Auth => ({
        user: undefined,

        setUser: (user) =>
          set((state) => {
            state.user = user;
          }),
      }),
    ),
  ),
);

export default useUserStore;
