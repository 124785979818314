import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import MainLayout from "./components/layout/main-layout";
import Chat from "./pages/chat.tsx";
import Comments from "./pages/comments.tsx";
import Dashboard from "./pages/dashboard";
import NotFound from "./pages/errors/not-found";
import LoginPage from "./pages/login-page";
import useUserStore from "./store/user-store.ts";
import ProtectedRoute from "./utils/protected-route/protected-route";

function App() {
  const queryClient = new QueryClient();
  const { user } = useUserStore();

  const children =
    user?.email === "china@influencer.com"
      ? [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/chat",
            element: <Chat />,
          },
          {
            path: "/comments",
            element: <Comments />,
          },
        ]
      : [
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/comments",
            element: <Comments />,
          },
        ];

  const router = createBrowserRouter([
    {
      path: "*",
      element: <NotFound />,
    },
    {
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <LoginPage />,
        },
        {
          element: <ProtectedRoute />,
          children: children,
        },
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
