/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckCouponAvailabilityResponse } from "../models/CheckCouponAvailabilityResponse";
import type { CheckCouponCodeRequest } from "../models/CheckCouponCodeRequest";
import type { CheckCouponCodeResponse } from "../models/CheckCouponCodeResponse";
import type { CouponResponse } from "../models/CouponResponse";
import type { CreateCouponRequest } from "../models/CreateCouponRequest";
import type { InfluencerAnalyticsByCouponResponse } from "../models/InfluencerAnalyticsByCouponResponse";
import type { PageableCouponResponse } from "../models/PageableCouponResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class CouponsApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create coupon
   * @param requestBody Create coupon body
   * @returns CouponResponse Success create coupon
   * @throws ApiError
   */
  public createCoupon(
    requestBody?: CreateCouponRequest,
  ): CancelablePromise<CouponResponse> {
    return this.httpRequest.request({
      method: "POST",
      url: "/admin/coupons/",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get coupons
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableCouponResponse Coupons
   * @throws ApiError
   */
  public getCoupons(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableCouponResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/coupons/",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get coupon by id
   * @param id
   * @returns CouponResponse Coupon
   * @throws ApiError
   */
  public getCouponById(id: string): CancelablePromise<CouponResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/coupons/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get coupon by userId
   * @returns CouponResponse Coupons
   * @throws ApiError
   */
  public getCouponByUserId(): CancelablePromise<CouponResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/influencer/coupons",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get analytics by coupon code
   * @param code
   * @returns InfluencerAnalyticsByCouponResponse Coupons
   * @throws ApiError
   */
  public getInfluencerAnalyticsByCoupon(
    code: string,
  ): CancelablePromise<InfluencerAnalyticsByCouponResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/influencer/coupons/{code}/analytics",
      path: {
        code: code,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check if there is any coupon available
   * @returns CheckCouponAvailabilityResponse Coupons
   * @throws ApiError
   */
  public checkAvailableCouponsExist(): CancelablePromise<CheckCouponAvailabilityResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/coupons/available",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check code exist
   * @param requestBody
   * @returns CheckCouponCodeResponse Check code exist
   * @throws ApiError
   */
  public checkCouponCodeExist(
    requestBody?: CheckCouponCodeRequest,
  ): CancelablePromise<CheckCouponCodeResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/admin/coupons/check-code",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        500: `Something went wrong`,
      },
    });
  }
}
