import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Button,
  DropdownMenu,
  Input,
} from "@ns/styles";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  getSortedRowModel,
  useReactTable,
  VisibilityState,
  // RowSelectionState,
} from "@tanstack/react-table";
import { t } from "i18next";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

import Skeleton from "../ui/skeleton";

interface DataTableProps<Tdata, Tvalue> {
  columns: ColumnDef<Tdata, Tvalue>[];
  data: Tdata[];
  filterBy?: string;
  filterInputPlaceholder?: string;
  columnFilter?: boolean;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  canNextPage?: boolean;
  canPreviousPage?: boolean;
  pageIndex?: number;
  loading?: boolean;
}

export function DataTable<Tdata, Tvalue>({
  columns,
  data,
  filterBy,
  filterInputPlaceholder,
  onNextPage,
  onPreviousPage,
  canNextPage,
  canPreviousPage,
  pageIndex,
  loading,
  columnFilter,
}: DataTableProps<Tdata, Tvalue>) {
  const [sort, setSort] = useState<SortingState>([]);
  const [filter, setFilter] = useState<ColumnFiltersState>([]);
  const [visibleColumns, setVisibleColumns] = useState<VisibilityState>({});
  // const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const table = useReactTable({
    data,
    columns,
    // getRowId: (row) => row.id,
    state: {
      sorting: sort,
      columnFilters: filter,
      columnVisibility: visibleColumns,
      // rowSelection,
    },
    onSortingChange: setSort,
    onColumnFiltersChange: setFilter,
    onColumnVisibilityChange: setVisibleColumns,
    // onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const renderSkeletonRows = () => {
    return Array.from({ length: 5 }).map((_, index) => (
      <TableRow key={index}>
        {columns.map((_, idx) => (
          <TableCell key={idx}>
            <Skeleton height="20px" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <>
      <div className="flex justify-between items-center py-4">
        {filterBy && (
          <div>
            <Input
              placeholder={filterInputPlaceholder}
              value={
                (table.getColumn(filterBy)?.getFilterValue() as string) ?? ""
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                table.getColumn(filterBy)?.setFilterValue(event.target.value)
              }
              className="max-w-sm"
            />
          </div>
        )}
        {columnFilter && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                <span>Columns</span>
                <ChevronDown />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value: boolean) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              renderSkeletonRows()
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => {
                return (
                  <TableRow
                    key={`${pageIndex}-${row.id}`}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={`${pageIndex}-${cell.id}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("common.noResults")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={onPreviousPage}
          disabled={!canPreviousPage}
        >
          {t("form.button.previous")}
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={onNextPage}
          disabled={!canNextPage}
        >
          {t("form.button.next")}
        </Button>
      </div>
    </>
  );
}
