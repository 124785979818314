import CardData, { CardDataType } from "./card-data";

type Props = {
  data: CardDataType[];
};

const CardList = ({ data }: Props) => {
  return (
    <div className="p-5 flex flex-wrap items-center justify-center gap-[36px]">
      {data.map((element, i) => (
        <CardData
          title={element.title}
          value={element.value}
          svg={element.svg}
          key={i}
        />
      ))}
    </div>
  );
};

export default CardList;
