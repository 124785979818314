/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateReferralRequest } from "../models/CreateReferralRequest";
import type { PageableGetReferredByResponse } from "../models/PageableGetReferredByResponse";
import type { PageableReferralResponse } from "../models/PageableReferralResponse";
import type { PageableWinningReferralResponse } from "../models/PageableWinningReferralResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class ReferralApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create referral
   * @param requestBody
   * @returns any Successfully created referral
   * @throws ApiError
   */
  public createReferral(
    requestBody?: CreateReferralRequest,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "POST",
      url: "/referrals",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get my referrals
   * @param currencyCode
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableReferralResponse Successfully get my referrals
   * @throws ApiError
   */
  public getReferrals(
    currencyCode?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableReferralResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/referrals",
      query: {
        currencyCode: currencyCode,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get my winning referrals
   * @param currencyCode
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableWinningReferralResponse Successfully get my winning referrals
   * @throws ApiError
   */
  public getWinningReferrals(
    currencyCode?: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableWinningReferralResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/referrals/winners",
      query: {
        currencyCode: currencyCode,
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get referred by
   * @param id
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns PageableGetReferredByResponse Successfully get referred by
   * @throws ApiError
   */
  public getReferredBy(
    id: string,
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<PageableGetReferredByResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/referred/{id}",
      path: {
        id: id,
      },
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
