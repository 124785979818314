'use client';

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from './toast';
import { useToast } from './use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <div className={'relative'} style={{ zIndex: 10000000001 }}>
      <ToastProvider>
        {toasts.map(function ({ id, title, description, action, variant, icon, ...props }) {
          return (
            <Toast
              className={`w-full h-[90px] mt-0.5 flex items-center self-end gap-4 ${variant === 'info' && 'toast-info'} ${variant === 'reminder' && 'toast-reminder'}`}
              key={id}
              variant={variant}
              {...props}
            >
              {icon && <div className={'w-[50px] md:w-[70px] h-auto'}>{icon}</div>}
              <div className={'flex flex-col gap-4 items-baseline'}>
                <div className="grid gap-1">
                  {title && <ToastTitle>{title}</ToastTitle>}
                  {description && <ToastDescription>{description}</ToastDescription>}
                </div>
                {action}
                <ToastClose />
              </div>
            </Toast>
          );
        })}
        <ToastViewport />
      </ToastProvider>
    </div>
  );
}
