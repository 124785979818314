/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationSetupResponse } from "../models/NotificationSetupResponse";
import type { UpdateNotificationSetup } from "../models/UpdateNotificationSetup";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class NotificationsSetupApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update notifications setup
   * @param requestBody
   * @returns any Successfully updated notifications setup
   * @throws ApiError
   */
  public updateNotificationsSetup(
    requestBody?: UpdateNotificationSetup,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/notifications-setup",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get notifications setup
   * @returns NotificationSetupResponse Get notifications setup
   * @throws ApiError
   */
  public getNotificationsSetup(): CancelablePromise<NotificationSetupResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/notifications-setup",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        500: `Something went wrong`,
      },
    });
  }
}
