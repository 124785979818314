import { CardHeader } from "@ns/styles/src/components/ui/card.tsx";
import { FC } from "react";

interface Props {
  logo: string;
  headerText: string;
}

const LoginCardHeader: FC<Props> = ({ logo, headerText }) => {
  return (
    <CardHeader className="gap-8 px-6 pt-0 pb-8 flex items-center">
      <img src={logo} className={"w-44 h-44"} alt="Logo" />
      <span className="w-max text-custom-title text-xl self-center">
        {headerText}
      </span>
    </CardHeader>
  );
};

export default LoginCardHeader;
