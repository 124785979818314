const Skeleton: React.FC<{
  width?: string;
  height?: string;
  className?: string;
}> = ({ width = "100%", height = "100%", className = "" }) => {
  return (
    <div
      className={`animate-pulse bg-gray-300 ${className}`}
      style={{ width, height }}
    />
  );
};

export default Skeleton;
