export const environmentVariables = {
  baseApiUrl: import.meta.env.VITE_BASE_API_URL,
  stytchClient: import.meta.env.VITE_STYTCH_CLIENT,
  stytchLoginRedirectUrl: import.meta.env.VITE_STYTCH_GOOGLE_LOGIN_REDIRECT_URL,
  stytchSignupRedirectUrl: import.meta.env
    .VITE_STYTCH_GOOGLE_SIGNUP_REDIRECT_URL,
  stytchResetPasswordRedirectUrl: import.meta.env
    .VITE_STYTCH_RESET_PASSWORD_REDIRECT_URL,
  sentryDsn: import.meta.env.VITE_INFLUENCER_SENTRY_DSN,
  nodeEnv: import.meta.env.VITE_NODE_ENV,
  sentryAppUrl: import.meta.env.VITE_INFLUENCER_SENTRY_APP_URL,
  sentryAuthToken: import.meta.env.VITE_INFLUENCER_SENTRY_AUTH_TOKEN,
  graphqlUrl: import.meta.env.VITE_GRAPHQL_API_URL,
  graphqlApiKey: import.meta.env.VITE_GRAPHQL_API_KEY,
};
