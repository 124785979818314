/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckEmailRequest } from "../models/CheckEmailRequest";
import type { CheckEmailResponse } from "../models/CheckEmailResponse";
import type { DeleteUserRequest } from "../models/DeleteUserRequest";
import type { UpdateUserRequest } from "../models/UpdateUserRequest";
import type { UserResponse } from "../models/UserResponse";
import type { UsersResponse } from "../models/UsersResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class UserApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get all users
   * @param limit
   * @param offset
   * @param name
   * @param email
   * @param status
   * @param sortBy
   * @param direction
   * @returns UsersResponse Get users response
   * @throws ApiError
   */
  public getUsers(
    limit?: number | string,
    offset?: number | string,
    name?: string,
    email?: string,
    status?: string,
    sortBy?: string,
    direction?: string,
  ): CancelablePromise<UsersResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/users",
      query: {
        limit: limit,
        offset: offset,
        name: name,
        email: email,
        status: status,
        sortBy: sortBy,
        direction: direction,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get user by id
   * @param id
   * @returns UserResponse User response
   * @throws ApiError
   */
  public getAdminUserById(id: string): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/users/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Get user by id
   * @param id
   * @returns UserResponse User response
   * @throws ApiError
   */
  public getUserById(id: string): CancelablePromise<UserResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/users/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Complete onboarding for endUsers
   * @returns any Successfully completed onboarding for endUsers
   * @throws ApiError
   */
  public completeOnboarding(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/users/onboarding",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update user language
   * @param id
   * @returns any Successfully updated user language
   * @throws ApiError
   */
  public updateUserLanguage(id: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/users/language/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Update user
   * @param requestBody
   * @returns any Successfully updated user
   * @throws ApiError
   */
  public updateUser(requestBody?: UpdateUserRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/users",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Delete user
   * @param requestBody
   * @returns any Successfully deleted user
   * @throws ApiError
   */
  public deleteUser(requestBody?: DeleteUserRequest): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/users",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Delete user wallet
   * @param currency
   * @returns any Successfully deleted user wallet
   * @throws ApiError
   */
  public deleteUserWallet(currency: "BTC" | "ETH"): CancelablePromise<any> {
    return this.httpRequest.request({
      method: "DELETE",
      url: "/users/wallet",
      query: {
        currency: currency,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }

  /**
   * Check influencer email
   * @param requestBody
   * @returns CheckEmailResponse Successfully checked influencer email
   * @throws ApiError
   */
  public checkInfluencerEmail(
    requestBody?: CheckEmailRequest,
  ): CancelablePromise<CheckEmailResponse> {
    return this.httpRequest.request({
      method: "PATCH",
      url: "/admin/users/influencers/check-email",
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
