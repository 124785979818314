import { useQuery } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";

import { api } from "../../api/client-api";

const ProtectedRoute = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["influencerWhoAmI"],
    queryFn: () => api.auth.getInfluencerWhoAmI(),
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <Navigate to="/" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
