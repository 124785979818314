import * as React from 'react';
import { ChangeEvent, MouseEventHandler } from 'react';

import { cn } from './../../lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  errors?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  autoFocus?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      placeholder,
      id,
      onChange,
      onClick,
      onBlur,
      value,
      errors,
      disabled,
      autoFocus,
      ...props
    },
    ref
  ) => {
    return (
      <input
        className={cn(
          'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
        placeholder={placeholder}
        value={value}
        onClick={onClick}
        onBlur={onBlur}
        onChange={onChange}
        id={id}
        name={props.name || ''}
        type={type}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
