import { Avatar, AvatarFallback, AvatarImage, Card } from "@ns/styles";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from "../api/client-api.ts";
import { OrderColumns } from "../components/data-table/columns/orders.tsx";
import { DataTable } from "../components/data-table/data-table.tsx";
import CardList from "../components/ui/card-list.tsx";

const Dashboard = () => {
  const { t } = useTranslation();

  const columns = OrderColumns();
  const [pageIndex, setPageIndex] = useState(0);

  const pageSize = 10;

  const { data: coupon, isLoading: isLoadingCoupon } = useQuery(
    ["couponByUserId"],
    () => api.coupons.getCouponByUserId(),
  );

  const { data: influencer, isLoading: isLoadingInfluencer } = useQuery({
    queryKey: ["getInfluencerById"],
    queryFn: () => api.influencer.getInfluencerById(coupon!.influencerId),
    enabled: !!coupon,
  });

  const { data: orders, isLoading: isLoadingOrders } = useQuery(
    ["influencerOrders", pageIndex, pageSize],
    () =>
      api.orders.getOrdersByPromoCode(
        coupon!.code,
        pageSize,
        pageIndex * pageSize,
      ),
    { keepPreviousData: true, enabled: !!coupon },
  );

  const { data: analytics, isLoading: isLoadingAnalytics } = useQuery(
    ["influencerAnalytics", pageIndex, pageSize],
    () => api.coupons.getInfluencerAnalyticsByCoupon(coupon!.code),
    { keepPreviousData: true, enabled: !!coupon },
  );

  const canPreviousPage = pageIndex > 0;
  const canNextPage = orders ? pageIndex < orders.totalPages - 1 : false;

  const onNextPage = () => {
    if (canNextPage) {
      setPageIndex((prev) => prev + 1);
    }
  };

  const onPreviousPage = () => {
    if (canPreviousPage) {
      setPageIndex((prev) => prev - 1);
    }
  };

  const totalBidsStatistics = useMemo(
    () =>
      analytics?.totalBids?.map((currencyBids) => {
        return {
          title: `${currencyBids.currency} ${t("common.totalBids")}`,
          value: currencyBids?.total,
        };
      }) ?? [],
    [analytics],
  );

  const stats = useMemo(
    () => [
      {
        title: t("common.promoCode"),
        value: (
          <div>
            {coupon?.code ?? "No Code"}
            <div className={"flex mt-4 flex-col text-sm font-normal"}>
              <div>
                {t("dashboard.validFrom")}: {coupon?.validFrom}
              </div>
              <div>
                {t("dashboard.validTo")}: {coupon?.validTo ?? "/"}
              </div>
            </div>
          </div>
        ),
      },
      ...totalBidsStatistics,
      {
        title: t("common.revenue"),
        value: analytics?.revenue.toFixed(2) ?? "0",
      },
    ],
    [totalBidsStatistics, analytics, coupon, t],
  );

  const isLoading = useMemo(
    () =>
      isLoadingAnalytics ||
      isLoadingCoupon ||
      isLoadingInfluencer ||
      isLoadingOrders,
    [isLoadingInfluencer, isLoadingOrders, isLoadingAnalytics, isLoadingCoupon],
  );

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <>
      <Card className="flex h-[130px] gap-5 p-7 items-center mb-8 justify-between">
        <div className="flex items-center gap-5">
          <Avatar>
            <AvatarImage src={`${influencer?.imageUrl}`} />
            <AvatarFallback className="text-3xl">
              {`${influencer?.firstName}}`[0].toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <span className="font-bold ">{`${influencer?.firstName} ${influencer?.lastName}`}</span>
            <span className="text-slate-400 antialiased">{`${influencer?.email}`}</span>
          </div>
        </div>
      </Card>
      <CardList data={stats} />
      <DataTable
        columns={columns}
        data={orders?.items || []}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageIndex={pageIndex}
        loading={isLoading}
      />
    </>
  );
};

export default Dashboard;
