import { Button, Input } from "@ns/styles";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import { ChatFeed, Message } from "react-chat-ui";

import { getAllMessages, sendMessage } from "../../graphql/api.ts";
import { TextWithLinks } from "../components/linkify-text.tsx";
import useUserStore from "../store/user-store.ts";

const Chat = () => {
  const { data, refetch, isLoading, isError } = useQuery({
    queryKey: [`getAllMessages`],
    queryFn: () => getAllMessages({ offset: 0, limit: 1000 }),
  });

  const { user } = useUserStore();

  const [messages, setMessages] = useState<unknown[]>([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    // @ts-ignore
    if (data?.getAllMessages?.items) {
      setMessages(
        // @ts-ignore
        data.getAllMessages?.items?.map(
          // @ts-ignore
          (message) =>
            new Message({
              id: message!.user!.id!,
              senderName: message!.user!.displayName!,
              // @ts-ignore
              message: (
                <TextWithLinks
                  text={`${message?.message} ${message?.imageUrl}`}
                />
              ),
            }),
        ),
      );
    }
  }, [data]);

  const handleSend = async () => {
    if (input.trim() === "") return;

    await sendMessage({
      chatData: {
        message: input,
        user: { id: user!.id, displayName: "Moderator" },
      },
    });

    await refetch(); // Refresh messages after sending a new one
    setInput("");
  };

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error :(</p>;

  return (
    <div>
      <ChatFeed
        messages={messages}
        hasInputField={false}
        showSenderName={true}
        bubbleStyles={{
          text: {
            fontSize: 16,
          },
        }}
      />
      <div className="flex w-full flex-row gap-4">
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSend();
            }
          }}
        />
        <Button onClick={handleSend}>Send</Button>
      </div>
    </div>
  );
};

export default Chat;
