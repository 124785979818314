/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetInfluencerByIdResponse } from "../models/GetInfluencerByIdResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import type { BaseHttpRequest } from "../core/BaseHttpRequest";

export class InfluencerApi {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get influencer by id
   * @param id
   * @returns GetInfluencerByIdResponse Influencer response
   * @throws ApiError
   */
  public getInfluencerById(
    id: string,
  ): CancelablePromise<GetInfluencerByIdResponse> {
    return this.httpRequest.request({
      method: "GET",
      url: "/admin/influencers/{id}",
      path: {
        id: id,
      },
      errors: {
        400: `Bad request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not found`,
        422: `Validation error`,
        500: `Something went wrong`,
      },
    });
  }
}
