import { Button, useToast } from "@ns/styles";
import { useMutation } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { api } from "../api/client-api";
import ControlledForm from "../components/form/controlled-form";
import ControlledInput from "../components/form/controlled-input";
import Loader from "../components/ui/loader";
import LoginArea from "../components/ui/login-area";
import LoginCardHead from "../components/ui/login-card-head";
import {
  LoginFormData,
  loginFormFieldNames,
  loginValidationSchema,
} from "../schemas/form/login-schema";
import useUserStore from "../store/user-store";

type LoginData = {
  password: string;
  email: string;
};

const LoginForm = () => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormContext<LoginFormData>();
  const navigate = useNavigate();
  const { setUser } = useUserStore();
  const { toast } = useToast();

  const { mutate: loginSubmit, isLoading } = useMutation({
    mutationFn: (data: LoginData) => api.auth.influencerLogin(data),
    onSuccess: async () => {
      const { id, firstName, lastName, email, imageUrl } =
        await api.auth.getInfluencerWhoAmI();
      setUser({
        id,
        email,
        firstName: firstName || "",
        lastName: lastName ?? "",
        imageUrl,
      });
      navigate("/dashboard");
    },
    onError: () => {
      toast({
        variant: "destructive",
        title: t("common.errors.error"),
        description: t("form.authentication.toast.loginError.description"),
      });
    },
  });

  const onSubmit = handleSubmit((data) => {
    loginSubmit({ email: data.email, password: data.password });
  });

  return (
    <div className="flex flex-col gap-8">
      <ControlledInput
        placeholder={t("form.authentication.email") || ""}
        name={loginFormFieldNames.email}
        id="email"
      />
      <ControlledInput
        placeholder={t("form.authentication.password") || ""}
        name={loginFormFieldNames.password}
        id="password"
        type="password"
      />
      <Button className={"mt-4"} onClick={onSubmit} disabled={isLoading}>
        {isLoading ? (
          <Loader size={6} margin={1} color="white" />
        ) : (
          t("form.button.submit")
        )}
      </Button>
    </div>
  );
};

const Login = () => {
  const logoUrl = import.meta.env.VITE_APP_LOGO_URL;
  const headerText = import.meta.env.VITE_APP_INFLUENCER_HEADER_TEXT;
  return (
    <LoginArea>
      <LoginCardHead logo={logoUrl} headerText={headerText} />
      <ControlledForm schema={loginValidationSchema}>
        <LoginForm />
      </ControlledForm>
    </LoginArea>
  );
};

export default Login;
