import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

export type OrderData = {
  id: string;
  username: string;
  email: string;
};
export const OrderColumns = (): ColumnDef<OrderData>[] => {
  const { t } = useTranslation();

  const columns: ColumnDef<OrderData>[] = [
    {
      accessorKey: "username",
      header: t("table.username"),
    },
    {
      accessorKey: "email",
      header: t("table.email"),
    },
    {
      accessorKey: "roundName",
      header: t("table.roundName"),
    },
    {
      accessorKey: "roundStatus",
      header: t("table.roundStatus"),
    },
    {
      accessorKey: "createdAt",
      header: t("table.timestamp"),
    },
  ];

  return columns;
};
